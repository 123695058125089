import "./App.css";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useState } from "react";
import { updateData } from "./services";

function App() {
  const [token, setToken] = useState();
  const [siren, setSiren] = useState('')

  useEffect(() => {
    setToken(Cookies.get("token"));
  }, []);

  function logout() {
    Cookies.remove("token", { domain: ".mymoons.mx" });
    setToken();
  }

  async function  update() {
    let res = await updateData({
      sessionToken: token,
      customerId: "YV3MY7RZ",
      deal: {
          SirenaId: siren
      }}
      )
  }
  

  return (
    <div className="App">
      <h1>App 2</h1>
      <h2>Token: {token}</h2>
      <input placeholder="SirenId" value={siren} onChange={(e) => setSiren(e.target.value)}></input>
      <button onClick={update}>Send</button>
      <button onClick={logout}>Logout</button>
    </div>
  );
}

export default App;
